/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-11-07 15:12:40
 * @FilePath: \h5-front\src\router\greenhouse.js
 * @Description: 温室管理
 */
const environmentalControl = () => import('@/views/greenhouse/environmental-control/index.vue')
const preConfigured=()=> import('@/views/greenhouse/pre-configured/index.vue')
const deviceControl = () => import('@/views/greenhouse/deviceControl/index.vue')
// const areaSensorInfo =() =>  import('@/views/greenhouse/environmental-control/area-sensor-info/index.vue');
const deviceAutoSet = () => import('@/views/greenhouse/deviceControl/device-auto-set/index.vue')
const videoSurveillance = () => import('@/views/greenhouse/video-surveillance/index.vue')
// children
const fan = () => import('@/views/greenhouse/deviceControl/fan/fan-index.vue')
const ventilation = () => import('@/views/greenhouse/deviceControl/ventilation/ventilation-index.vue')
const sensors = () => import('@/views/greenhouse/deviceControl/sensors/sensors-index.vue')
const shading = () => import('@/views/greenhouse/deviceControl/shading/shading-index.vue')
const shelter = () => import('@/views/greenhouse/deviceControl/shelter/shelter-index.vue')
const lighting = () => import('@/views/greenhouse/deviceControl/lighting/lighting-index.vue')
const heating = () => import('@/views/greenhouse/deviceControl/heating/heating-index.vue')
const supplementaryLighting = () => import('@/views/greenhouse/deviceControl/supplementaryLighting/supplementary-lighting-index.vue')
const humidification = () => import('@/views/greenhouse/deviceControl/humidification/humidificate-index.vue')
const dehumidification = () => import('@/views/greenhouse/deviceControl/dehumidification/dehum-index.vue')

// 设备类型未知页面
const unknown = () => import('@/views/greenhouse/deviceControl/unknown/unknown-index.vue')

function createComponent(component, chunkName, path, title, pertain, layout = "ui", children = []) {
  return {
    // path: `/greenhouse/${path}/:id?/:title?`,// 进入设备总控需要传id和title
    path: `/greenhouse/${path}`,// 进入设备总控需要传id和title
    name: chunkName,
    component,
    meta: {
      requiresAuth: true,
      layout,
      title,
      pertain,
    },
    children: children,
  }
}

export default [
  // controlCentercontrolCenter环控中心 greenhouseManage温室管理
  createComponent(environmentalControl, 'greenhouse-control', 'environmentalControl', 'menuName.controlCenter', 'menuName.greenhouseManage'),
  // createComponent(areaSensorInfo, 'area-sensor-info', 'environmental-control/area-sensor-info', '传感器', '温室管理', 'default'),

  createComponent(deviceControl, 'device-control', 'deviceControl', '设备总控', '温室管理', 'default',
    [
      createComponent(ventilation, 'ventilation', 'deviceControl/ventilation/ventilationIndex', '通风', '设备总控', 'default'),
      createComponent(sensors, 'sensors', 'deviceControl/sensors/sensors-index', '传感器', '设备总控', 'default'),
      createComponent(shading, 'shading', 'deviceControl/shading/shadingIndex', '拉幕', '设备总控', 'default'),
      createComponent(shelter, 'shelter', 'deviceControl/shelter/shelter-index', '遮荫', '设备总控', 'default'),
      createComponent(fan, 'fan', 'deviceControl/fan/fanindex', '风机', '设备总控', 'default'),
      createComponent(lighting, 'lighting', 'deviceControl/lighting/lighting-index', '照明', '设备总控', 'default'),
      createComponent(heating, 'heating', 'deviceControl/heating/heatingIndex', '加热', '设备总控', 'default'),
      createComponent(supplementaryLighting, 'supplementaryLighting', 'deviceControl/supplementaryLighting/supplementaryLightingIndex', '补光', '设备总控', 'default'),
      createComponent(humidification, 'humidification', 'deviceControl/humidification/humidificate-index', '加湿', '设备总控', 'default'),
      createComponent(dehumidification, 'dehumidification', 'deviceControl/dehumidification/dehum-index', '除湿', '设备总控', 'default'),
      createComponent(unknown, 'unknown', 'deviceControl/unknown/unknown-index', '未知', '设备总控', 'default'),
    ]

  ),

  createComponent(preConfigured, 'pre-configured', 'preConfigured', '预配置', '温室管理', 'ui'),
  createComponent(deviceAutoSet, 'device-autoSet', 'deviceControl/device-auto-set/index', '设备自动控制', 'greenhouse-device', 'default'),
  createComponent(videoSurveillance, 'greenhouse-video', 'video-surveillance/index', '视频监控', '温室管理', 'ui'),
]
