/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-11-15 17:51:37
 * @FilePath: \h5-front\src\router\home-page.js
 * @Description: 我的主页
 */
const sensorAlarmSet = () =>
  import('@/views/pages/sensor-group/alarm/sensor-alarm-set.vue')

export default [
  {
    path: '/',
    redirect: '/home-page',
    meta: {}
  },
  {
    path: '/home-page',
    name: 'home',
    meta: {
      requiresAuth: true,
      layout: 'landing'
    },
    component: () => import('@/views/pages/home-page.vue')
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: () =>
      import(
        /* webpackChunkName: "error" */ '@/views/errors/not-found-page.vue'
      )
  },

  //   主页 - 传感器报警设置【从主页进入时，要传递传感器id】
  {
    path: '/sensor-alarm-set/:id',
    name: 'sensor-alarm-set',
    component: sensorAlarmSet,
    meta: {
      requiresAuth: true,
      layout: 'default'
    }
  }
]
