<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-05-24 14:22:31
 * @FilePath: \js-yigrow-system\src\components\navigation\bis-operate.vue
 * @Description: 切换商户
-->
<template>
  <div class="pa-3 pb-0 mt-2">
    <v-autocomplete v-model="bisStore.bis" :items="userStore.bisList" return-object item-title="name"
      :key="bisStore.bis?.id" item-value="id" variant="outlined" density="compact"
      item-color="primary"></v-autocomplete>

    <v-card class="text-disabled" subtitle="测试使用：后期删除" v-if="false">
      <v-card-text>
        <small>产品类型：{{ bisStore.productTypeList.join() || '--' }}</small>
        <br />
        <small>角色权限：{{ bisStore.rolePermission.join() || '--' }}</small>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { useAppStore } from '@/stores/app-store'
import { useBisStore } from '@/stores/bis'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const bisStore = useBisStore()
const appStore = useAppStore()

// const setCurrentBis = (selBis) => {
//   appStore.setIsJumpProduct(true) //需要默认跳转产品页
//   bisStore.changeBis(selBis)
// }

watch(
  () => bisStore.bis.id,
  (val, old) => {
    // if (old) {
    console.log('bisStore.bis, old :', val, old)
    bisStore.changeBis(val)
    // setCurrentBis(val)
    // }
  },
  // { deep: true, }  // 无用
)
</script>

<style scoped></style>
