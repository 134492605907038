/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-07-11 09:24:47
 * @FilePath: \js-yigrow-system\src\configs\public.js
 * @Description: 统一配置
 */

export const version = '0.2.5'
export const bisId = ''
export const appId = ''

export const baseUrl = import.meta.env.VITE_APP_API_BASEURL
export const urlPrefix = '/v1'
export const apiUrl = import.meta.env.VITE_API_BASE_URL
const wxAuthUrl = 'https://demo.yigrow.cn/wechat/get-code.html'
const appUrl = import.meta.env.VITE_APP_URL
export const redirectUri = `${wxAuthUrl}?appid=${appId}&state=yigrow&redirect_uri=${encodeURIComponent(`${appUrl}/#/login`)}`
export const registerUri = `${wxAuthUrl}?appid=${appId}&state=register&scope=snsapi_userinfo&redirect_uri=${encodeURIComponent(`${appUrl}/#/login`)}`

console.log(`version:${version}, apiUrl: ${apiUrl}, baseUrl: ${baseUrl}`,)

export const isDev = process.env.NODE_ENV === 'development'

export const api = {}
export const tokenName = 'authorization'
export const Bearer = ''
export const ttl = 7200
export const timeout = 60 * 1000  // dafault 60s
export const pagingTtl = 900
export const userInfoTTL = 3600 * 24 * 7
export const httpRetrys = 2
export const __biz = 'Mzg2NDk3NTE4NA=='
export const gotoUrl = `https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=${__biz}&scene=124#wechat_redirect`
export const SUPER_VALUE = 2

export const useFrontEndErrorMsg = false // 默认采用后端返回异常
export const stagnateTime = 60 * 60 * 1000 // 无操作停滞时间，默认1小时
export const openAutoJumpOut = true // 是否开启无操作跳出

export const cosConf = {
  Region: 'ap-chengdu',
  Bucket: 'cd-1252817934',
}