/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-07-15 09:11:43
 * @FilePath: \js-yigrow-system\src\plugins\vuetify.js
 * @Description: plugins/vuetify.js
 *  * Framework documentation: https://vuetifyjs.com`
 */

// Composables
import { createVuetify } from "vuetify"

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
// import { zhHans } from 'vuetify/locale'

import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n"
import { useI18n } from "vue-i18n"
import i18n from "@/plugins/i18n"

// 实验室组件
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import { VNumberInput } from 'vuetify/labs/VNumberInput'
import { VPullToRefresh } from 'vuetify/labs/VPullToRefresh'
import { VDateInput } from 'vuetify/labs/VDateInput'

const Lighttheme = {
  dark: false,
  variables: {
    "high-emphasis-opacity": 1,
  },
  colors: {
    background: "#f2f5f8",
    surface: "#ffffff",
    primary: "#344767",
    secondary: "#334155",
    accent: "#705CF6",
    error: "#ef476f",
    info: "#2196F3",
    success: "#06d6a0",
    "on-success": "#ffffff",
    warning: "#ffd166",
  },
}

const Darktheme = {
  dark: true,
  colors: {
    background: "#111b27",
    surface: "#1E293B",
    primary: "#705CF6",
    secondary: "#598EF3",
    accent: "#705CF6",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
  },
}

export default createVuetify({
  components: {
    VTimePicker, VNumberInput,VPullToRefresh,VDateInput
  },
  // locale: {
  //   locale: 'zhHans',
  //   messages: { zhHans },
  // },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  theme: {
    themes: {
      light: Lighttheme,
      dark: Darktheme,
    },
  },
  icons: {
    defaultSet: 'mdi',
  },
  defaults: {
    VBtn: {
      // rounded: "md",
      // fontWeight: "400",
      letterSpacing: "0",
    },
    VCard: {},
    VSheet: {
      elevation: 1,
    },
    VTable: {
      elevation: 1,
    },
    VDataTable: {
      fixedHeader: true,
      noDataText: "没有数据",
    },
    VTextField: {
      variant: "outlined",
    },
    VSelect: {
      variant: "outlined",
    },
  },
})
